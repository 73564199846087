import React from "react";
import Layout from "../../Layout";
import "./style.scss";

// import SEO from "../../seo";

export default function MandatoryDisclosure() {
  return (
    <Layout>
      <div className="pdfview">
        <h1 className="heading">Mandatory Public Disclosure - Appendix - IX</h1>

        <div
          type="application/pdf"
          width="100%"
          height="100%"
          className="pdfview__container"
        >
          <object
            type="application/pdf"
            width="100%"
            height="100%"
            className=""
            data="https://chinmayavvdelhi.ac.in/assets/pdfs/APPENDIX-IX-MANDATORY-PUBLIC-DISCLOSURE.pdf"
          ></object>
        </div>
      </div>
    </Layout>
  );
}
